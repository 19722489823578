import './style.scss';

import classNames from 'classnames';
import PageDefault from 'components/PageDefault';
import PageNewsletter from 'components/PageNewsletter';
import PageSmart from 'components/PageSmart';
import PinchToZoom from 'components/PinchToZoom';
import SheetGesture from 'components/SheetGesture';
import SheetList from 'components/SheetList';
import PagePreviewContainer from 'containers/PagePreviewContainer';
import { ZOOM } from 'helper/js/constants';
import { isSmartPage } from 'helper/js/guards';
import { compareReactNodes } from 'helper/js/helper';
import { ReferenceContext } from 'providers/Reference/index';
import { SheetContext, SheetContextProps } from 'providers/Sheet';
import { Component, ContextType, ReactNode } from 'react';

type SheetProps = {
  handleSwipeGestures: (direction: string) => void;
  onZoomUpdate: (newZoom: number) => void;
  enableMediaLinks: boolean;
  pages: APagesItem[];
  navigate: boolean;
  animationType: string;
  currentPage: number;
  flipPages: number;
  currentVisible: number;
  isWideScreen: boolean;
  zoom: number;
  newsletterUrl?: string;
  flipToPageNumber?: number;
  recommendedFlyer?: ARecommendedFlyerItem;
  offsetHorizontal: number;
  offsetVertical: number;
  isTouchDevice: boolean;
};
type SheetState = {
  prePages: ReactNode;
  currentPages: ReactNode;
  nextPages: ReactNode;
  isResizing: boolean;
  config: { width: number; height: number };
  flyerWrapperHeight: number;
  flyerWrapperWidth: number;
  dragging: boolean;
  cookieHeight: number;
};

export default class Sheet extends Component<SheetProps, SheetState> {
  static contextType = ReferenceContext;

  context!: ContextType<typeof ReferenceContext>;

  static defaultProps = {
    flipPages: 1,
    currentVisible: 1,
    isWideScreen: false,
    animationType: '',
    flipToPageNumber: null,
    offsetHorizontal: 0,
    offsetVertical: 0,
    pages: [],
    recommendedFlyer: {},
    zoom: 1,
    onZoomUpdate: () => {},
    handleSwipeGestures: () => {},
    enableMediaLinks: true,
  };

  renderedPages = {};

  sheetContextData: SheetContextProps;

  flyerWrapperHeight = Math.floor(this.props.pages[0].height);

  flyerWrapperWidth = Math.floor(this.props.pages[0].width);

  resizeTimer?: NodeJS.Timeout = undefined;

  constructor(props: SheetProps) {
    super(props);

    this.state = {
      cookieHeight: 0,
      prePages: undefined,
      currentPages: undefined,
      nextPages: undefined,
      isResizing: false,
      flyerWrapperHeight: Math.floor(props.pages[0].height),
      flyerWrapperWidth: Math.floor(props.pages[0].width),
      config: {
        width: 0,
        height: 0,
      },
      dragging: false,
    };

    this.sheetContextData = {
      cCurrentVisible: props.currentVisible,
      cZoom: props.zoom,
      cSheetWidth: 0,
      cPageWidth: 0,
      cSheetHeight: 0,
    };
  }

  shouldComponentUpdate(prevProps: SheetProps, prevState: SheetState) {
    const {
      navigate,
      currentPage,
      offsetVertical,
      offsetHorizontal,
      animationType,
      currentVisible,
      enableMediaLinks,
      flipPages,
      flipToPageNumber,
      isWideScreen,
      zoom,
      isTouchDevice,
    } = this.props;

    return (
      navigate !== prevProps.navigate ||
      currentPage !== prevProps.currentPage ||
      offsetHorizontal !== prevProps.offsetHorizontal ||
      offsetVertical !== prevProps.offsetVertical ||
      animationType !== prevProps.animationType ||
      currentVisible !== prevProps.currentVisible ||
      enableMediaLinks !== prevProps.enableMediaLinks ||
      flipPages !== prevProps.flipPages ||
      flipToPageNumber !== prevProps.flipToPageNumber ||
      isWideScreen !== prevProps.isWideScreen ||
      zoom !== prevProps.zoom ||
      this.state.isResizing !== prevState.isResizing ||
      this.state.config.height !== prevState.config.height ||
      this.state.config.width !== prevState.config.width ||
      this.state.dragging !== prevState.dragging ||
      isTouchDevice !== prevProps.isTouchDevice ||
      compareReactNodes(this.state.currentPages, prevState.currentPages) ||
      compareReactNodes(this.state.prePages, prevState.prePages) ||
      compareReactNodes(this.state.nextPages, prevState.nextPages)
    );
  }

  handleResize = () => {
    if (!this.state.isResizing) {
      this.setState({ isResizing: true });
    }
    this.resizeTimer && clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => {
      this.setState({ isResizing: false });
      if (this.props.zoom > ZOOM.MIN) {
        this.props.onZoomUpdate(ZOOM.MIN);
      }
    }, 100);
  };

  componentDidMount() {
    this.setState({
      prePages: this.getPrePages(),
      currentPages: this.getCurrentPages(),
      nextPages: this.getNextPages(),
    });
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('orientationchange', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('orientationchange', this.handleResize);
  }

  static getDerivedStateFromProps(props: SheetProps, state: SheetState) {
    const flyerWrapperWidth = state.flyerWrapperWidth * 2;
    const { flyerWrapperHeight } = state;
    const mainContentHeight = window.innerHeight + props.offsetVertical - state.cookieHeight;
    const mainContentWidth = window.innerWidth + props.offsetHorizontal;

    const factor = Math.min(
      mainContentHeight / flyerWrapperHeight,
      (mainContentWidth / flyerWrapperWidth) * (props.currentVisible === 1 ? 2 : 1),
    );

    const newHeight = Math.floor(flyerWrapperHeight * factor);
    const newWidth = Math.floor(flyerWrapperWidth * factor);

    if (state.config.height !== newHeight || state.config.width !== newHeight) {
      return {
        config: {
          height: newHeight,
          width: newWidth,
        },
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { navigate, flipPages, flipToPageNumber, enableMediaLinks } = this.props;
    if (prevState.config.height <= 0) {
      this.setState({
        prePages: this.getPrePages(),
        currentPages: this.getCurrentPages(),
        nextPages: this.getNextPages(),
      });
    }

    if (enableMediaLinks !== prevProps.enableMediaLinks) {
      this.setState({
        currentPages: this.getCurrentPages(),
      });
    }

    if (flipToPageNumber && flipToPageNumber !== prevProps.flipToPageNumber) {
      this.setState({
        prePages: this.getPrePages(flipToPageNumber),
        nextPages: this.getNextPages(flipToPageNumber),
      });
    }

    if (!navigate && (prevProps.navigate !== navigate || prevProps.flipPages !== flipPages)) {
      this.setState({
        prePages: this.getPrePages(),
        currentPages: this.getCurrentPages(),
        nextPages: this.getNextPages(),
      });
    }
  }

  getCurrentClasses = () => {
    const { currentPage, pages, navigate, animationType, isWideScreen } = this.props;
    const classes = classNames({
      sheet__list: true,
      [`sheet__list--${animationType}`]: navigate,
      'sheet__list--single-page':
        ((currentPage === 1 || pages.length === currentPage) && !navigate) || !isWideScreen,
    });
    return classes;
  };

  getPrePages = (startPage?: number) => {
    const { pages, currentPage, flipPages } = this.props;
    const start = startPage ? startPage - 1 : currentPage - 1 - flipPages;
    const end = startPage ? startPage - 1 + flipPages : currentPage - 1;
    return this.renderPages(pages.slice(start <= 0 ? 0 : start, end), 'pre');
  };

  getCurrentPages = () => {
    const { pages, currentVisible, currentPage } = this.props;
    return this.renderPages(
      pages.slice(currentPage - 1, currentPage - 1 + currentVisible),
      'current',
      true,
    );
  };

  getNextPages = (startPage?: number) => {
    const { pages, currentVisible, currentPage } = this.props;
    const start = startPage ? startPage - 1 : currentPage - 1 + currentVisible;
    const end = start + 2;
    return this.renderPages(pages.slice(start, end), 'next');
  };

  renderPageType = ({
    page,
    visible,
    className,
    isLastPage,
  }: {
    page: APagesItem | ASmartPageItem;
    visible: boolean;
    className: string;
    isLastPage: boolean;
  }) => {
    const { newsletterUrl, recommendedFlyer } = this.props;

    if (isSmartPage(page)) {
      return (
        <PageSmart
          key={page.id}
          elements={page.elements}
          isLastPage={isLastPage}
          pageId={page.id}
          className={className}
          visible={visible}
        />
      );
    }
    switch (page.type) {
      case 'newsletter':
        return (
          <PageNewsletter
            key={page.id}
            className={className}
            visible={visible}
            newsletterUrl={newsletterUrl}
            isLastPage={isLastPage}
          />
        );
      case 'recommended':
        return (
          <PagePreviewContainer
            key={page.id}
            className={className}
            visible={visible}
            recommendedFlyer={recommendedFlyer}
            isLastPage={isLastPage}
          />
        );

      default:
        return (
          <PageDefault
            key={page.id}
            {...page}
            pageId={page.id}
            className={className}
            visible={visible}
            isLastPage={isLastPage}
          />
        );
    }
  };

  renderPages = (pages: APagesItem[], type: string, visible = false) =>
    pages.map((page: APagesItem, index: number) =>
      this.renderPageType({
        page,
        className: `page--${type} page--${type}-${index + 1}`,
        visible,
        isLastPage: this.props.pages.length === this.props.currentPage + index && visible,
      }),
    );

  handleScaleUpdated = (scale: number) => {
    if (scale !== this.props.zoom) {
      this.props.onZoomUpdate(scale);
    }
  };

  handleSwipeGestures = (direction: string) => {
    this.props.handleSwipeGestures(direction);
  };

  handlePanEvents = (position: string) => {
    this.setState({
      dragging: position === 'start',
    });
  };

  getSheetContextData = () => {
    const { cCurrentVisible, cZoom, cSheetWidth, cPageWidth, cSheetHeight } = this.sheetContextData;
    const newContextData = {
      cCurrentVisible: this.props.currentVisible,
      cZoom: this.props.zoom,
      cSheetWidth: this.state.config.width,
      cPageWidth: this.state.config.width / 2,
      cSheetHeight: this.state.config.height,
    };
    if (
      cCurrentVisible === newContextData.cCurrentVisible &&
      cZoom === newContextData.cZoom &&
      cSheetWidth === newContextData.cSheetWidth &&
      cPageWidth === newContextData.cPageWidth &&
      cSheetHeight === newContextData.cSheetHeight
    ) {
      return this.sheetContextData;
    }
    this.sheetContextData = newContextData;
    return this.sheetContextData;
  };

  render() {
    const { navigate, animationType, isTouchDevice } = this.props;
    const { config, prePages, currentPages, nextPages, dragging } = this.state;
    const isSheetLoaded = config.width === 0 && config.height === 0;
    const sheetClassNames = classNames({
      sheet: true,
      'sheet--zooming': this.props.zoom > 1,
      'sheet--dragging': dragging,
      'sheet--disableMediaLinks': !this.props.enableMediaLinks,
      'sheet--transition': navigate,
      [`sheet--transition-${animationType}`]: navigate && animationType !== '',
      'sheet--notrans': this.state.isResizing || isSheetLoaded,
    });
    const resetZoom = () => {
      this.props.onZoomUpdate(ZOOM.MIN);
    };

    return (
      <SheetGesture
        resetZoom={resetZoom}
        swipeActive={this.props.zoom <= ZOOM.MIN}
        handleSwipeGestures={this.handleSwipeGestures}>
        <PinchToZoom
          isTouchDevice={isTouchDevice}
          onScaleUpdated={this.handleScaleUpdated}
          zoom={this.props.zoom}
          currentPage={this.props.currentPage}
          maxZoomScale={ZOOM.MAX}
          onPanEvent={this.handlePanEvents}>
          <SheetContext.Provider value={this.getSheetContextData()}>
            <div className={sheetClassNames} style={{ ...config }}>
              {!isSheetLoaded && (
                <SheetList
                  prePages={prePages}
                  currentPages={currentPages}
                  nextPages={nextPages}
                  classNames={this.getCurrentClasses()}
                />
              )}
            </div>
          </SheetContext.Provider>
        </PinchToZoom>
      </SheetGesture>
    );
  }
}
