import { AxiosInstance } from 'axios';

import { getBaseWithoutUuid } from '../helper/js/helper';
import { checkResponse, leafletAPI, leafletCMS } from './api';
import { fallBackUrls } from './fallbackUrls';

interface LEAFLET_RESPONSE {
  success: boolean;
  flyer: any;
}

interface LEAFLET_FALLBACK_RESPONSE {
  success?: boolean;
  fallback: FallbackUrls;
}

interface FallbackUrls {
  imprintUrl: string;
  privacyUrl: string;
  quitUrl: string;
}

async function getLeafletData(
  api: AxiosInstance,
  endpoint: string,
  searchParams: URLSearchParams | undefined = undefined,
): Promise<LEAFLET_RESPONSE> {
  try {
    const response = await api.get(endpoint, { ...(searchParams && { params: searchParams }) });
    checkResponse(response, 'flyer');
    return response.data;
  } catch (e) {
    throw new Error(`FLYER RESPONSE ERROR ${endpoint}`);
  }
}

export async function getSmartPageDraft(
  uuid: string,
  smartPageDraft: string,
  version: string | undefined,
): Promise<LEAFLET_RESPONSE> {
  const smartPageDraftEndpoint = `smartpage/${uuid}/draft/${smartPageDraft}/flyer.json?version=${version}`;
  return getLeafletData(leafletCMS(), smartPageDraftEndpoint);
}

export async function getLeaflet(
  uuid: string,
  regionId?: number,
  storeId?: number,
): Promise<LEAFLET_RESPONSE> {
  const leafletEndpoint = 'flyer';
  const searchParams = new URLSearchParams({
    flyer_identifier: uuid,
  });

  if (typeof regionId !== 'undefined' && regionId >= 0) {
    searchParams.append('region_id', `${regionId}`);
    searchParams.append('region_code', `${regionId}`);
  }
  if (typeof storeId !== 'undefined' && storeId >= 0) {
    searchParams.append('store_id', `${storeId}`);
  }

  return getLeafletData(leafletAPI(), leafletEndpoint, searchParams);
}

export async function getLeafletFallback(): Promise<LEAFLET_FALLBACK_RESPONSE> {
  const leafletFallbackEndpoint = `flyer-fallback?domain=${getBaseWithoutUuid()}`;

  try {
    const response = await leafletAPI().get(leafletFallbackEndpoint);
    checkResponse(response, 'fallback');
    return response.data;
  } catch (e) {
    const tenant = import.meta.env.VITE_THEME;
    const tenantFallbackUrls = fallBackUrls[tenant] ?? {};
    if (!tenantFallbackUrls) {
      console.error(`No fallback URLs defined for tenant: ${tenant}`);
    }
    return {
      fallback: tenantFallbackUrls,
    };
  }
}
