import { memo, useMemo } from 'react';

import SmartPageImageDropzone from './SmartPageImageDropzone';

type Props = {
  onChoose?: (image: string) => void;
  image: string;
  size: string;
  alignY: string;
  alignX: string;
};

const SmartPageImage = ({ onChoose, image, size, alignX, alignY }: Props) => {
  const renderBgImage = size !== 'default';

  const getBgImage = useMemo(
    () => (
      <div
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundPosition: `${alignX} ${alignY}`,
          backgroundImage: `url(${image})`,
          backgroundSize: size,
          height: '100%',
          width: '100%',
        }}
      />
    ),
    [alignX, alignY, image, size],
  );

  const getImage = useMemo(() => {
    const correctAlign = {
      left: 'flex-start',
      top: 'flex-start',
      center: 'center',
      right: 'flex-end',
      bottom: 'flex-end',
    };
    return (
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          zIndex: 50,
          justifyContent: correctAlign[alignX],
          alignItems: correctAlign[alignY],
        }}>
        <img style={{ maxHeight: '100%', maxWidth: '100%' }} src={image} alt="" />
      </div>
    );
  }, [alignX, alignY, image]);

  const getImageType = useMemo(() => {
    if (!image) {
      return null;
    }
    return renderBgImage ? getBgImage : getImage;
  }, [getBgImage, getImage, image, renderBgImage]);

  return (
    <>
      {getImageType}
      {onChoose && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            zIndex: 100,
          }}>
          <SmartPageImageDropzone onChange={onChoose} defaultImage={image} />
        </div>
      )}
    </>
  );
};
export default memo(SmartPageImage);
