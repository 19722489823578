import { ENVIRONMENT } from 'helper/js/constants';
import { errorReporting } from 'helper/js/helper';
import {
  Component,
  ComponentType,
  createContext,
  memo,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { getTranslations } from '../../api/translations';

export type LanguageContextProps = {
  t: (key: string, ignoreNoKeyFound?: boolean) => string;
};

export const LanguageContext = createContext<LanguageContextProps>({
  t: (key: string, ignoreNoKeyFound?: boolean) => key,
});

const LanguageProvider = ({ children }: PropsWithChildren) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [translations, setTranslations] = useState<IKeyValueData>({});
  const showTranslationKeys = useSelector((state: AppState) => state.settings.showTranslationKeys);
  const clientLocale = useSelector((state: AppState) => state.appData.clientLocale);

  const loadTranslations = useCallback(async () => {
    try {
      const response = await getTranslations(clientLocale);
      setTranslations(response.translations);
      setSuccess(response.success);
    } catch (err) {
      await getFallBackData();
    }
  }, [clientLocale]);

  useEffect(() => {
    loadTranslations();
  }, [loadTranslations]);

  const getTranslationKey = useCallback(
    () => (key: string, ignoreNoKeyFound?: boolean) => {
      if (key in translations) {
        return showTranslationKeys ? `t('${key}')` : translations[key];
      }
      if (!ignoreNoKeyFound) {
        console.warn(`Translation key not found: ${key} clientLocale: ${clientLocale}`);
        if (import.meta.env.NODE_ENV === ENVIRONMENT.PRODUCTION) {
          errorReporting(`Translation key not found: ${key} clientLocale: ${clientLocale}`);
        }
      }

      return key;
    },
    [clientLocale, showTranslationKeys, translations],
  );

  const contextData: LanguageContextProps = useMemo(
    () => ({ t: getTranslationKey() }),
    [getTranslationKey],
  );

  const getFallBackData = async () => {
    const defaultData =
      import.meta.env.VITE_THEME !== 'kaufland'
        ? await import('./languageDefault.json')
        : await import('./languageDefault_kaufland.json');
    setTranslations(defaultData.default.translations);
    setSuccess(true);
  };

  return (
    <LanguageContext.Provider value={contextData}>{success && children}</LanguageContext.Provider>
  );
};

export default memo(LanguageProvider);

export function withLanguage<OriginalProps>(
  UnwrappedComponent: ComponentType<OriginalProps & LanguageContextProps>,
) {
  type Props = Omit<OriginalProps, keyof LanguageContextProps> & OwnProps;
  return class WithLanguage extends Component<Props, object> {
    static readonly WrappedComponent = UnwrappedComponent;

    render() {
      const Comp = UnwrappedComponent;
      return (
        <LanguageContext.Consumer>
          {({ t }) => <Comp {...(this.props as OriginalProps)} t={t} />}
        </LanguageContext.Consumer>
      );
    }
  };
}

export type LanguageProps = {
  children: (t: (key: string) => string) => ReactNode;
};

export class Language extends Component<LanguageProps, object> {
  render() {
    return <LanguageContext.Consumer>{({ t }) => this.props.children(t)}</LanguageContext.Consumer>;
  }
}

const useLanguage = () => {
  const { t } = useContext(LanguageContext);
  return { t };
};

export { useLanguage };
