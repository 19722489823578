import './style.scss';

import { isTouch } from 'helper/js/guards';
import { TrackingContextProps, withTracking } from 'providers/Tracking';
import { MouseEvent, PropsWithChildren, PureComponent, TouchEvent } from 'react';

const SWIPE_DISTANCE = 50;

type SheetGestureProps = {
  handleSwipeGestures: (direction: string) => void;
  swipeActive?: boolean;
  resetZoom: Function;
};

class SheetGesture extends PureComponent<
  PropsWithChildren<SheetGestureProps & TrackingContextProps>,
  {}
> {
  movement = 0;

  start?: number = undefined;

  dragging = false;

  tappedTwice = false;

  componentDidMount() {
    document.addEventListener('touchmove', this.preventScaling, {
      passive: false,
    });
    document.addEventListener('touchstart', this.preventScaling, {
      passive: false,
    });
    document.addEventListener('touchend', this.preventScaling, {
      passive: false,
    });
  }

  componentWillUnmount() {
    document.removeEventListener('touchmove', this.preventScaling);
    document.removeEventListener('touchstart', this.preventScaling);
    document.removeEventListener('touchend', this.preventScaling);
  }

  preventScaling = (e: any) => {
    if (e.touches && e.touches.length > 1) {
      e.preventDefault();
    }
  };

  getNormalizedPointerPosition = (e: TouchEvent | MouseEvent): number => {
    if (isTouch(e)) {
      return e.touches[0].screenX;
    }

    return e.screenX;
  };

  handleTouchStart = (e: TouchEvent | MouseEvent): void | boolean => {
    if (isTouch(e) && e.touches.length > 1) {
      return;
    }

    this.dragging = true;
    this.start = this.getNormalizedPointerPosition(e);
  };

  handleTouchEnd = (e: TouchEvent | MouseEvent) => {
    if (!this.dragging || (isTouch(e) && e.touches.length > 1)) {
      return;
    }

    const { handleSwipeGestures } = this.props;
    if (this.movement && this.props.swipeActive) {
      if (this.movement > SWIPE_DISTANCE) {
        handleSwipeGestures('backward');
      } else if (this.movement < -SWIPE_DISTANCE) {
        handleSwipeGestures('forward');
      }
    }
    this.dragging = false;
    this.start = undefined;
    this.movement = 0;
  };

  handleTouchMove = (e: TouchEvent | MouseEvent) => {
    if (!this.dragging || (isTouch(e) && e.touches.length > 1)) {
      return;
    }
    const normalizedPointerPosition = this.getNormalizedPointerPosition(e);
    this.movement = normalizedPointerPosition - (this.start ?? 0);
  };

  render() {
    return (
      <div
        className="sheetgesture"
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}>
        {this.props.children}
      </div>
    );
  }
}

export default withTracking(SheetGesture);
