import classNames from 'classnames';
import {
  createElement,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  memo,
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import styles from './index.module.scss';
import useResizeText from './resizeTextHook';

type Props = {
  text: string;
  tag: string;
  color: string;
  orientation: string;
  contentEditable?: boolean;
  onBlur?: (event: FocusEvent<HTMLSpanElement>) => void;
  onFocus?: (event: FocusEvent<HTMLSpanElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLSpanElement>) => void;
  boxRef: RefObject<HTMLDivElement>;
  height?: number | null;
};

enum TEXT_TYPE {
  PARAGRAPH = 'p',
  HEADLINE = 'h1',
}

enum TEXT_SIZE_MIN {
  PARAGRAPH = 12,
  HEADLINE = 18,
}

enum TEXT_SIZE_RECOMMENDED {
  PARAGRAPH = 20,
  HEADLINE = 30,
}

const getMinFontSize = (tag: string) =>
  tag === TEXT_TYPE.PARAGRAPH ? TEXT_SIZE_MIN.PARAGRAPH : TEXT_SIZE_MIN.HEADLINE;
const getMaxFontSize = (tag: string) =>
  tag === TEXT_TYPE.PARAGRAPH ? TEXT_SIZE_RECOMMENDED.PARAGRAPH : TEXT_SIZE_RECOMMENDED.HEADLINE;

const SmartPageText = forwardRef<HTMLParagraphElement | HTMLHeadingElement | undefined, Props>(
  (
    {
      boxRef,
      text,
      tag,
      color,
      orientation,
      contentEditable = false,
      onBlur = () => {
        return;
      },
      onFocus = () => {
        return;
      },
      onKeyDown = () => {
        return;
      },
      height,
    },
    ref,
  ) => {
    const minFontSize = getMinFontSize(tag);
    const maxFontSize = getMaxFontSize(tag);
    const scaleStep = 1;
    const localRef = useRef();
    const fitSize = useRef<number | null>(null);
    const currentFontSize = useRef<number | null>(null);

    const setFontSize = useCallback(
      (textContainer: HTMLParagraphElement | HTMLHeadingElement, fontSize: number) => {
        currentFontSize.current = fontSize;
        textContainer.style.fontSize = `${fontSize}px`;
      },
      [],
    );

    const resizeText: () => void = useCallback(() => {
      const reff = (ref ?? localRef) as MutableRefObject<HTMLParagraphElement | HTMLHeadingElement>;
      if (boxRef.current && reff?.current) {
        const boxContainer = boxRef.current;
        const textContainer = reff.current;

        const compStyles = window.getComputedStyle(textContainer);
        const fontSizePx = compStyles.getPropertyValue('font-size');
        const fontSize = parseInt(fontSizePx, 10);

        if (textContainer.offsetHeight > boxContainer.offsetHeight && minFontSize < fontSize) {
          setFontSize(textContainer, fontSize - scaleStep);
          return resizeText();
        }

        if (textContainer.offsetHeight < boxContainer.offsetHeight && maxFontSize > fontSize) {
          fitSize.current = fontSize;
          setFontSize(textContainer, fontSize + scaleStep);
          if (textContainer.offsetHeight > boxContainer.offsetHeight) {
            setFontSize(textContainer, fitSize.current);
            return;
          }
          return resizeText();
        }
      }
    }, [boxRef, maxFontSize, minFontSize, ref, setFontSize]);

    /**
     * This effect is used to resize the text if the editor changes the viewport of the Smartpage
     */
    useEffect(() => {
      if (contentEditable) {
        resizeText();
      }
    }, [height, resizeText, contentEditable]);

    useResizeText(resizeText);

    const textClasses = classNames({
      [styles.text]: true,
      [styles[tag]]: true,
    });

    return createElement(
      tag,
      {
        className: textClasses,
        ref: ref ?? localRef,
        style: { color, textAlign: orientation },
        contentEditable,
        suppressContentEditableWarning: true,
        onKeyDown,
        onBlur,
        onFocus,
      },
      text,
    );
  },
);

export default memo(SmartPageText);
