import useEventListener from 'helper/js/hooks/use-event-listener';
import { useCallback, useEffect, useRef } from 'react';

const useResizeText = (callback: Function = () => {}) => {
  const resizeTimer = useRef<NodeJS.Timeout | null>(null);
  const savedHandler = useRef<any>(callback);

  const resizeButtonThrottle = useCallback(() => {
    resizeTimer.current && clearTimeout(resizeTimer.current);
    resizeTimer.current = setTimeout(() => callback(), 100);
  }, [callback]);

  useEffect(() => {
    savedHandler.current();
  }, []);

  useEventListener('resize', resizeButtonThrottle);
};

export default useResizeText;
