import './reboot.scss';
import './style.scss';

import { ReferenceContext } from 'providers/Reference';
import { memo, ReactNode, useContext, useEffect, useRef } from 'react';

type Props = {
  children: ReactNode;
};
const Content = ({ children }: Props) => {
  const referenceContext = useContext(ReferenceContext);
  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (contentRef) {
      referenceContext.saveToRef(contentRef, 'sheet');
    }
  }, [referenceContext]);
  return (
    <div ref={contentRef} className="maincontent">
      {children}
    </div>
  );
};

export default memo(Content);
