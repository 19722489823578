import ButtonC, { BUTTON_APPEARANCE, BUTTON_SIZE } from 'components/ButtonC';
import { LINK_TARGET } from 'components/Link';
import styles from 'components/SmartPageBox/index.module.scss';
import { forwardRef, memo, MutableRefObject, useCallback, useContext, useState } from 'react';

import { TrackingContext } from '../../providers/Tracking';
import TRACKING_EVENTS from '../../providers/Tracking/constants';
import TRACKING_EVENT_COLLECTIONS from '../../providers/Tracking/trackingEventCollections';
import { SmartPageBoxContext } from './context';
import useResizeText from './resizeTextHook';

type Props = {
  label: string;
  type: BUTTON_APPEARANCE;
  url: string;
  target: LINK_TARGET;
  additionalProps?: IKeyValueData;
  alignY: string;
  alignX: string;
  icon: string;
  name: string;
  pageId: string;
};

const BUTTON_LARGE_HEIGHT = 48;

const correctAlign = {
  left: 'flex-start',
  top: 'flex-start',
  center: 'center',
  right: 'flex-end',
  bottom: 'flex-end',
};

const SmartPageButton = forwardRef<HTMLSpanElement, Props>(
  ({ label, type, url, target, additionalProps, alignY, alignX, icon, name, pageId }, ref) => {
    const { boxRef } = useContext(SmartPageBoxContext);
    const { trackEventUniversalAnalytics, trackEventDataLayerService } =
      useContext(TrackingContext);
    const [size, setSize] = useState<BUTTON_SIZE>(BUTTON_SIZE.L);
    const resizeButton = useCallback(() => {
      if (boxRef.current) {
        if (boxRef.current.offsetHeight > BUTTON_LARGE_HEIGHT && size === BUTTON_SIZE.S) {
          setSize(BUTTON_SIZE.L);
        } else if (boxRef.current.offsetHeight <= BUTTON_LARGE_HEIGHT && size === BUTTON_SIZE.L) {
          setSize(BUTTON_SIZE.S);
        }
      }
    }, [size, boxRef]);
    const handleButtonClick = useCallback(() => {
      const trackObject = {
        ...TRACKING_EVENTS.SMARTPAGE_BUTTON_CLICKED,
        eventAction: `${name}_clicked`,
        eventLabel: pageId,
      };
      trackEventUniversalAnalytics(trackObject);
      trackEventDataLayerService(TRACKING_EVENT_COLLECTIONS.SMART_PAGE_BUTTON_CLICKED, {
        contentLabel: `${pageId}_${name}`,
      });
    }, [name, pageId, trackEventUniversalAnalytics, trackEventDataLayerService]);

    useResizeText(resizeButton);
    return (
      <div
        className={styles.button}
        style={{
          justifyContent: correctAlign[alignX],
          alignItems: correctAlign[alignY],
        }}>
        <ButtonC
          ref={ref as MutableRefObject<HTMLSpanElement>}
          icon={icon}
          size={size}
          label={label}
          type={type}
          href={url}
          target={target}
          blurAfterClick={false}
          additionalProps={additionalProps}
          onClick={handleButtonClick}
        />
      </div>
    );
  },
);

export default memo(SmartPageButton);
