import './index.scss';

import classNames from 'classnames';
import NotificationItem from 'components/Notification/NotificationItem';
import { memo, useCallback, useContext, useEffect, useState } from 'react';

import { TrackingContext } from '../../providers/Tracking';
import TRACKING_EVENTS from '../../providers/Tracking/constants';
import NotificationButton from './NotificationButton';

type Props = {
  notifications?: ANotificationData[];
  showNotification: boolean;
  hasNewNotification: boolean;
  updateSeenNotifications?: (ids: string[]) => void;
};

const Notification = ({
  notifications = [],
  showNotification,
  hasNewNotification,
  updateSeenNotifications = () => {},
}: Props) => {
  const [isVisible, setIsVisible] = useState(showNotification);
  const { trackEventUniversalAnalytics } = useContext(TrackingContext);

  const notificationClassNames = classNames({
    notification: true,
    'notification--visibile': isVisible,
  });

  const notificationListClassNames = classNames({
    notification__list: true,
    'notification__list--visibile': isVisible,
  });

  const handleClick = useCallback(() => {
    if (isVisible) {
      trackEventUniversalAnalytics({
        ...TRACKING_EVENTS.NOTIFICATION_CLOSE_BUTTON_CLICKED,
      });
    } else {
      trackEventUniversalAnalytics({
        ...TRACKING_EVENTS.NOTIFICATION_OPEN_BUTTON_CLICKED,
      });
    }
    setIsVisible((visible) => !visible);
  }, [trackEventUniversalAnalytics, setIsVisible, isVisible]);

  useEffect(() => {
    if (isVisible) {
      updateSeenNotifications(notifications.map((notification) => notification.id));
    }
  }, [isVisible, notifications, updateSeenNotifications]);

  if (notifications.length <= 0) {
    return null;
  }

  return (
    <div className={notificationClassNames}>
      <NotificationButton
        isVisible={isVisible}
        onClick={handleClick}
        hasNewNotification={hasNewNotification}
      />
      <ul className={notificationListClassNames}>
        {notifications.map(({ id, title, htmlMessage, type }) => (
          <NotificationItem key={id} title={title} message={htmlMessage} type={type} />
        ))}
      </ul>
    </div>
  );
};

export default memo(Notification);
