import './style.scss';

import SocialShareItem from 'components/SocialShareItem';
import { SOCIAL_SHARE_ITEMS } from 'helper/js/constants';
import { getCapitalizedThemeName } from 'helper/js/helper';
import { memo, useContext } from 'react';

import { LanguageContext } from '../../providers/Language';

interface SocialShareType {
  name: string;
  url: string;
  icon: string;
  title: string;
}

type SocialShareProps = {
  shareUrl?: string;
  headline?: string;
  subject?: string;
  type?: SocialShareType[];
  isWishlistShare?: boolean;
};

const SocialShare = ({
  shareUrl,
  headline,
  type = Object.values(SOCIAL_SHARE_ITEMS),
  subject = `${getCapitalizedThemeName()} Flyer`,
  isWishlistShare = false,
}: SocialShareProps) => {
  const { t } = useContext(LanguageContext);

  return (
    <div className="social__share__container">
      {isWishlistShare && <div className="social__share__top">{t('wishListShare')} ...</div>}
      {headline && <p className="social__headline">{headline}</p>}
      <ul
        className={`social__share__items ${
          isWishlistShare ? 'social__share__items--wishlist' : ''
        }`}>
        {Object.values(type).map(({ url, name, icon, title }) => (
          <SocialShareItem
            isWishlistShare={isWishlistShare}
            key={name}
            href={`${url}${shareUrl}`}
            service={name}
            icon={icon}
            title={title}
          />
        ))}
        <SocialShareItem
          isWishlistShare={isWishlistShare}
          key="email"
          href={`mailto:?subject=${subject}&body=${shareUrl}`}
          service="email"
          title="E-Mail"
          icon={`envelope-close--${import.meta.env.VITE_THEME}`}
        />
      </ul>
    </div>
  );
};

export default memo(SocialShare, () => true);
