import {
  showAgeRestriction,
  updateAgeRestriction,
} from 'containers/AgeRestrictionContainer/actions';
import { notificationLoaded } from 'containers/NotificationContainer/actions';
import { wishListChangedSaga } from 'containers/WishListContainer/actions';
import initialState from 'initialState';
import { updateUrl } from 'providers/Router/actions';
import { settingsUpdate } from 'providers/Settings/actions';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import FlyerValidationService from 'service/FlyerValidationService';

import { getLeaflet, getLeafletFallback, getSmartPageDraft } from '../../api/leaflets';
import { flyerDataError, flyerDataFallback, flyerDataInProgress, flyerDataLoaded } from './actions';
import { FLYER_DATA_LOADING } from './constants';

export const getRouterData = (state: AppState): ARouting => state.routing;
export const getWishListData = (state: AppState): AWishlistFlyerData[] => state.wishlistProducts;
export const getAgeRestrictionData = (state: AppState): AAgeRestriction => state.ageRestriction;

export function* loadFlyer() {
  yield takeLatest(FLYER_DATA_LOADING, loadFlyerData);
}

function fetchFlyerData(routerParams: ARouting) {
  const {
    searchQuery: { smartpagedraft, version, ar: promotionAr },
    params: { uuid, ar, storeId },
  } = routerParams;

  if (uuid) {
    if (smartpagedraft) {
      return getSmartPageDraft(uuid, smartpagedraft, version);
    }

    const getRegionId = (): number | undefined => {
      if (promotionAr) {
        return parseInt(promotionAr, 10);
      }
      if (ar) {
        return parseInt(ar, 10);
      }

      return undefined;
    };

    const getStoreId = (): number | undefined => {
      if (storeId) {
        return parseInt(storeId, 10);
      }

      return undefined;
    };

    return getLeaflet(uuid, getRegionId(), getStoreId());
  }
  throw new Error('NO UUID GIVEN');
}

async function getFallBackData() {
  const fallbackData = await getLeafletFallback();
  return fallbackData.fallback;
}
function* loadFlyerData() {
  let appState: AAppData = initialState.appData;
  yield put(flyerDataInProgress());
  const routerData = yield select(getRouterData);
  const wishListData = yield select(getWishListData);
  const ageRestrictionData = yield select(getAgeRestrictionData);

  try {
    const flyerData = yield call(fetchFlyerData, routerData);
    FlyerValidationService.flyerData = flyerData;
    FlyerValidationService.routerSearchQuery = routerData.searchQuery;
    FlyerValidationService.routerParams = routerData.params;
    FlyerValidationService.wishlistData = wishListData;
    FlyerValidationService.ageRestrictionData = ageRestrictionData;
    FlyerValidationService.transformFlyerData();

    appState = FlyerValidationService.appState;

    if (routerData.params.locale === '') {
      yield put(updateUrl({ locale: appState.locale }, true));
    }

    FlyerValidationService.validateFlyerData();

    if (FlyerValidationService._hasAgeRestriction) {
      yield put(updateAgeRestriction(FlyerValidationService.ageRestrictionData.accepted));
      yield put(showAgeRestriction(flyerData.flyer.ageRestriction));
    }

    yield put(notificationLoaded(FlyerValidationService.notification));

    yield put(
      flyerDataLoaded({
        ...appState,
      }),
    );

    yield FlyerValidationService.checkProducts();
    const outdatedProducts = FlyerValidationService.getOutdatedProductIds();

    yield all(
      outdatedProducts.map((item: any) =>
        put(wishListChangedSaga({ key: item, variant: 'default' })),
      ),
    );

    yield put(
      flyerDataLoaded({
        ...FlyerValidationService.appState,
      }),
    );

    yield put(settingsUpdate(FlyerValidationService.settings));
  } catch (e) {
    const fallBackUrlsLocale = yield getFallBackData();
    const locale = appState.locale || routerData.params.locale || '';
    yield put(
      flyerDataFallback({
        locale,
        gtmId: fallBackUrlsLocale.gtmID,
        urls: {
          imprintUrl: fallBackUrlsLocale.imprintUrl,
          privacyUrl: fallBackUrlsLocale.privacyUrl,
          quitUrl: fallBackUrlsLocale.quitUrl,
          homeUrl: fallBackUrlsLocale.homeUrl,
        },
      }),
    );
    yield put(
      flyerDataError({
        payload: e,
      }),
    );
  }
}
