import { MouseEvent as ReactMouseEvent, TouchEvent as ReactTouchEvent } from 'react';
import ProductVariant from 'service/ProductVariant';

export const isProductVariant = (product: any): product is ProductVariant =>
  (product as ProductVariant).productOptionsAsString !== undefined;
export const isTouch = (e: ReactTouchEvent | ReactMouseEvent): e is ReactTouchEvent =>
  window.TouchEvent && e.nativeEvent instanceof TouchEvent;

export const isSmartPage = (page: any): page is ASmartPageItem =>
  page.pageType !== undefined && page.elements !== undefined;

export const isObject = (data: any): data is object => typeof data === 'object';

type Feature = 'CART' | 'ACCOUNT';
export const isFeatureEnabled = (feature: Feature): boolean => {
  return import.meta.env[`VITE_FEATURE_${feature}`] === 'enabled';
};
