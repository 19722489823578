import { memo, ReactNode, RefObject, useRef } from 'react';

import { PAGESMART_CONTENT } from '../PageSmart';
import { SmartPageBoxContext } from './context';
import style from './index.module.scss';

type Props = {
  location: ILocation;
  children: ({ ref }: { ref: RefObject<HTMLDivElement> }) => ReactNode;
  type: string;
};

const SmartPageBox = ({ location: { height, width, top, left }, children, type }: Props) => {
  const boxRef = useRef<HTMLDivElement>(null);
  return (
    <SmartPageBoxContext.Provider value={{ boxRef }}>
      <div
        ref={boxRef}
        className={type === PAGESMART_CONTENT.LINK ? 'mediagroup' : style.box}
        style={{
          top: `${top}%`,
          left: `${left}%`,
          height: `${height}%`,
          width: `${width}%`,
        }}>
        {children({ ref: boxRef })}
      </div>
    </SmartPageBoxContext.Provider>
  );
};

export default memo(SmartPageBox);
