import { BUTTON_APPEARANCE_TYPE } from 'components/ButtonC';
import Page from 'components/Page';
import SmartPageBg from 'components/SmartPageBox/SmartPageBg';
import SmartPageBox from 'components/SmartPageBox/SmartPageBox';
import SmartPageButton from 'components/SmartPageBox/SmartPageButton';
import SmartPageImage from 'components/SmartPageBox/SmartPageImage';
import SmartPageText from 'components/SmartPageBox/SmartPageText';
import { memo, RefObject, useMemo } from 'react';

import MediaGroupsContainer from '../../containers/MediaGroupsContainer';
import { LINK_TARGET } from '../Link';

export enum PAGESMART_CONTENT {
  IMAGE = 'image',
  TEXT = 'text',
  BUTTON = 'button',
  BGCOLOR = 'background_color',
  LINK = 'link',
}

export type PAGESMART_CONTENT_TYPE =
  | PAGESMART_CONTENT.IMAGE
  | PAGESMART_CONTENT.TEXT
  | PAGESMART_CONTENT.BUTTON;

type PageSmartProps = {
  elements: SmartElementContentAnyType[];
  className?: string;
  visible?: boolean;
  isLastPage?: boolean;
  pageId: string;
};

type PageSmartContentProps = {
  element: SmartElementContentAnyType;
  boxRef: RefObject<HTMLDivElement>;
  pageId: string;
};

const PageSmartContent = ({ element, boxRef, pageId }: PageSmartContentProps) => {
  const getBgColor = useMemo(() => {
    const _data = element.data as DataBg;
    return <SmartPageBg color={_data.color} />;
  }, [element.data]);

  const getButton = useMemo(() => {
    const _data = element.data as DataButton;
    return (
      <SmartPageButton
        label={_data.label}
        type={_data.style as BUTTON_APPEARANCE_TYPE}
        url={element.action?.url || '#'}
        target={(element.action?.target as LINK_TARGET) || '_blank'}
        alignX={_data.alignX}
        alignY={_data.alignY}
        icon={_data.icon}
        name={element.name}
        pageId={pageId}
      />
    );
  }, [element, pageId]);

  const getImage = useMemo(() => {
    const _data = element.data as DataImage;
    return (
      <SmartPageImage
        image={_data.zoom || _data.image}
        size={_data.size}
        alignX={_data.alignX}
        alignY={_data.alignY}
      />
    );
  }, [element.data]);

  const getText = useMemo(() => {
    const _data = element.data as DataText;
    return (
      <SmartPageText
        boxRef={boxRef}
        text={_data.text || ''}
        tag={_data.style}
        color={_data.color}
        orientation={_data.orientation}
      />
    );
  }, [element.data, boxRef]);

  if (element.type === PAGESMART_CONTENT.BUTTON) {
    return getButton;
  }

  if (element.type === PAGESMART_CONTENT.TEXT) {
    return getText;
  }

  if (element.type === PAGESMART_CONTENT.IMAGE) {
    return getImage;
  }

  if (element.type === PAGESMART_CONTENT.BGCOLOR) {
    return getBgColor;
  }

  return null;
};

const PageSmart = ({
  elements = [],
  className = '',
  visible = false,
  isLastPage = false,
  pageId,
}: PageSmartProps) => (
  <Page className={className} isLastPage={isLastPage} isVisible={visible} type="smart">
    <div className="page__wrapper page__wrapper--notscale">
      {elements.map((element: SmartElementContentAnyType, index: number) => {
        if (element.type !== PAGESMART_CONTENT.LINK) {
          return (
            <SmartPageBox key={index} location={element.location} type={element.type}>
              {({ ref }) => <PageSmartContent boxRef={ref} element={element} pageId={pageId} />}
            </SmartPageBox>
          );
        }
        return null;
      })}
    </div>
    {visible && elements.filter((element) => element.type === PAGESMART_CONTENT.LINK) && (
      <MediaGroupsContainer pageId={pageId} />
    )}
  </Page>
);

export default memo(PageSmart);
