import classNames from 'classnames';
import { memo, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import styles from './Dropzone.module.scss';
import svgUrlReupload from './images/reupload.svg';
import svgUrlUpload from './images/upload.svg';

type Props = {
  onChange?: (image: string) => void;
  defaultImage: string;
};

const SmartPageImageDropzone = ({ defaultImage, onChange = () => {} }: Props) => {
  const [file, setFile] = useState<string>(defaultImage);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': [],
    },

    onDrop: (acceptedFiles) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const binaryStr = reader.result;
        setFile(binaryStr as string);
        onChange(binaryStr as string);
      };

      acceptedFiles.forEach((file) => reader.readAsDataURL(file));
    },
  });

  const handleDelete = useCallback(() => {
    setFile('');
    onChange('');
  }, [onChange]);
  const dropzoneClasses = classNames({
    [styles.dropzone]: true,
    [styles.active]: isDragActive,
  });

  const { onClick, ...rootprops } = getRootProps({
    className: dropzoneClasses,
    onClick: () => {},
  });
  return (
    <section {...rootprops} data-html2canvas-ignore>
      {file && (
        <div className={styles.toolbox}>
          <img onClick={onClick} src={svgUrlUpload} alt="" className={styles.x} />
          <img onClick={handleDelete} src={svgUrlReupload} alt="" className={styles.x} />
        </div>
      )}
      <input {...getInputProps()} />

      {(!file || (file && isDragActive)) && (
        <>
          <img
            onClick={onClick}
            src={!file ? svgUrlUpload : svgUrlReupload}
            alt=""
            className={styles.image}
          />
          <p className={styles.text}>
            {isDragActive
              ? 'Drop the files here ...'
              : "Drag 'n' drop a file here, or click to select a file"}
          </p>
        </>
      )}
    </section>
  );
};
export default memo(SmartPageImageDropzone);
