import { memo } from 'react';

type Props = {
  color: string;
};

const SmartPageBg = ({ color }: Props) => (
  <div style={{ backgroundColor: color, height: '100%', width: '100%' }} />
);
export default memo(SmartPageBg);
